<template>
  <div>
    <div class="uk-margin-top">
      <div class="uk-card uk-card-default kandang-card">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Nama Tahapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.name"
                  v-validate="'required'"
                  class="uk-input"
                  type="text"
                  name="nama"
                  placeholder="Masukan nama tahapan..."
                  :class="{'uk-form-danger': errors.has('nama')}"
                >
                <div
                  v-show="errors.has('nama')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('nama') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Urutan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.sequence"
                  v-validate="'required|numeric'"
                  class="uk-input"
                  type="text"
                  name="urutan"
                  placeholder="0"
                  :class="{'uk-form-danger': errors.has('urutan')}"
                >
                <div
                  v-show="errors.has('urutan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('urutan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Tipe Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="cage_category" 
                  v-model="cageCategoryChoose" 
                  v-validate="'required'"
                  label="name" 
                  name="tipe_kandang" 
                  track-by="id" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataCageCategorys" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true"
                  :options-limit="10"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectCageCategory"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('tipe_kandang')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('tipe_kandang') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Catatan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <textarea
                  id=""
                  v-model="formData.note"
                  name="catatan"
                  :class="{'uk-textarea': true, 'uk-form-danger': errors.has('catatan')}"
                  rows="5"
                  placeholder="Tulis catatan"
                />
                <div
                  v-show="errors.has('catatan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('catatan') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-text-right">
            <button
              class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
              type="button"
              @click="goToBack"
            >
              <img
                v-lazy="`${images}/icon/close.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Batal
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              @click="showSaveConfirmModal"
            >
              <img
                v-lazy="`${images}/icon/save.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin-top uk-margin-bottom">
      <div class="uk-card uk-card-default kandang-card">
        <div
          v-for="(row, i) in formActivities"
          :key="i"
          uk-margin
        >
          <div>
            <div class="uk-container">
              <div class="uk-align-left">
                <h6 class="uk-text-bold">
                  Kegiatan {{ i + 1 }}
                </h6>
              </div>
              <div class="uk-align-right">
                <button
                  class="uk-button uk-button-default-danger uk-margin-left"
                  type="button"
                  @click="removeActivities(i)"
                >
                  <img
                    v-lazy="`${images}/icon/trash.svg`"
                    alt=""
                    class="uk-margin-small-right"
                  >
                  Hapus
                </button>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kegiatan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <multiselect 
                      :id="`name_activity[${i}]`" 
                      v-model="row.id"
                      label="name" 
                      track-by="id" 
                      placeholder="Pilih" 
                      open-direction="bottom" 
                      :options="dataDetailActivityCategorys" 
                      :searchable="true" 
                      :loading="isLoading"
                      :close-on-select="true" 
                      :options-limit="50"
                      :max-height="500"
                      :show-no-results="true" 
                      @remove="clearDetailActivity(i)"
                      @select="selectDetailActivity($event, i)"
                    >
                      <span slot="noResult">Oops! Data tidak ditemukan.</span>
                    </multiselect>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                />
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl" />
            </div>
            <br>
            <hr>
          </div>
        </div>
        <h2
          class="add-activity-line"
          @click="addActivities()"
        >
          <span>
            <img
              v-lazy="`${images}/icon/plus-activity.svg`"
              alt=""
              class="uk-margin-small-left"
            >
            Tambah Kegiatan
          </span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/id'
import { PREFIX_IMAGE } from '@/utils/constant'
import { STATUS } from '@/utils/constant'

export default {
  data() {
    return {
      isShowFarm: false,
      isLoading: false,
      images: PREFIX_IMAGE,
      status: STATUS,
      datepick: new Date(),
      statusSwitch: true,
      cageCategoryChoose: '',
      formData: {},
      metaFilterActivityCategory: {
        name: '',
        status: 'true'
      },
      activityCategoryChoose: '',
      dataActivityCategorys: [],
      formActivities: [],
      detailActivityCategoryChoose: '',
      dataDetailActivityCategorys: [],
      dataCageCategorys: []
    }
  },
  computed: {
    ...mapGetters({
      activityCategorys: 'activityCategory/activityCategorys',
      metaActivityCategory: 'activityCategory/meta',
      detailActivityCategorys: 'detailActivityCategory/detailActivityCategorys',      
      metaDetailActivityCategory: 'detailActivityCategory/meta',
      cageCategorys: 'cageCategory/cageCategorys'
    })
  },
  watch: {
    async metaActivityCategory() {
      this.isLoading = true
      await this.getActivityCategory(this.metaActivityCategory)
      this.isLoading = false
      this.dataActivityCategorys = this.activityCategorys ? this.activityCategorys.data ? this.activityCategorys.data : [] : []
    },
    async metaDetailActivityCategory() {
      this.is_loading = true
      await this.getDetailActivityCategory(this.metaDetailActivityCategory)
      this.dataDetailActivityCategorys = this.detailActivityCategorys ? this.detailActivityCategorys.data ? this.detailActivityCategorys.data : [] : []
      this.is_loading = false
    },
    cageCategorys(){
      this.dataCageCategorys = this.cageCategorys ? this.cageCategorys ? this.cageCategorys : [] : []
    }
  },
  async created() {
    await this.getDetailActivityCategory(this.metaDetailActivityCategory)
    await this.changeLimitDetailActivityCategory()
  },
  async mounted() {
    await this.getCageCategory({is_all: true})
  },
  methods: {
    ...mapActions({
      getActivityCategory: 'activityCategory/getActivityCategory',
      getDetailActivityCategory: 'detailActivityCategory/getDetailActivityCategory',
      getCageCategory: 'cageCategory/getCageCategory'
    }),
    ...mapMutations({
      setModalAdd: 'activity/SET_MODAL_ADD',
      setMetaActivityCategory: 'activityCategory/SET_META',
      setMetaDetailActivityCategory: 'detailActivityCategory/SET_META'
    }),
    removeActivities (index) {
      this.formActivities.splice(index, 1)
    },
    addActivities () {
      this.formActivities.push(
        {
          activity_category: '',
          activity_category_id: '',
          description: '',
          id: '',
          input_type: '',
          input_type_id: '',
          name: '',
          sub_activity_category: '',
          sub_activity_category_id: '',
          time_start: '',
          time_end: ''
        }
      )
    },
    selectDetailActivity(val,i) {
      this.formActivities[i].name = val.name
      this.formActivities[i].activity_category_id = val.activity_category_id
      this.formActivities[i].sub_activity_category_id = val.sub_activity_category_id
      this.formActivities[i].id = val.id
      this.formActivities[i].input_type_id = val.input_type_id
      this.formActivities[i].description = val.description
      this.formActivities[i].time_start = val.time_start
      this.formActivities[i].time_end = val.time_end
      this.formActivities[i].activity_category = val.activity_category
      this.formActivities[i].input_type = val.input_type
      this.formActivities[i].sub_activity_category = val.sub_activity_category
    },
    clearDetailActivity (i) {
      this.formActivities[i].id = ''
      this.formActivities[i].name = ''
      this.formActivities[i].activity_category_id = ''
      this.formActivities[i].sub_activity_category_id = ''
      this.formActivities[i].input_type_id = ''
      this.formActivities[i].description = ''
      this.formActivities[i].time_start = ''
      this.formActivities[i].time_end = ''
      this.formActivities[i].activity_category = ''
      this.formActivities[i].input_type = ''
      this.formActivities[i].sub_activity_category = ''
    },
    findActivityCategory(q) {
      q = q.replace(/[^\w\s]/gi, '')
      this.metaFilterActivityCategory.name = q
      this.handleFindActivityCategory()
    },
    handleFindActivityCategory () {
      this.setMetaActivityCategory({
        ...this.metaActivityCategory,
        ...this.metaFilterActivityCategory
      })
    },
    async changeLimitDetailActivityCategory() {
      this.setMetaDetailActivityCategory({
        ...this.metaDetailActivityCategory,
        limit: this.detailActivityCategorys.meta.total_count
      })
    },
    onSelectCageCategory(val) {
      this.formData.cage_category_id = val ? String(val.id) : ''
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.formData['list_master_log_detail_activity'] = this.formActivities
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setModalAdd(this.formData)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>

<style scoped>
.width-large {
  width: 20.5%;
}
.width-small {
  width: 7%;
}
#toggle-animation {
  transition: width 500ms;
}
.card-sidebar {
  height: calc(100vh - 50px);
}
.detail-sidebar {
  padding-right: 10%;
  background-color: #E0EBE6;
  height: 50px;
  line-height: 50px;
}
.detail-sidebar a {
  color: #ffffff;
}
.sidebar {
  background-color: #E0EBE6;
  border-radius: 16px 0px 0px 16px;
  height: 600px;
}
li {
  padding: 8px 0;
}
.uk-active {
  background-color: #004428;
  border-left: 5px solid #004428;
}

.uk-active .uk-icon {
  background-color: #f1f2f3 ;
  color: #f1f2f3;
}
li.uk-active a {
  color: #cacccb;
}
.uk-nav-header {
  color: #fff;
}
.uk-nav-default li a.just-icon {
  padding: 0;
  text-align: center;
}
.active{
  background-color: #004428;
  color: #004428;
}
.active > a{
  color: #fff !important;
}
.uk-nav-default .uk-nav-sub a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
}
.uk-card-body .uk-nav-default > li > a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
}
@media (min-width: 1200px) {
  .uk-card-body .uk-nav-default > li > a {
    padding-right: 14px;
  }
}
.uk-nav-default .uk-nav-sub a {
    margin-left: 50px;
}
.uk-maintenance {
  background-color: #f1f3f2;
  border-radius: 16px 0px 0px 16px;
}
.uk-nav-default > li > a:hover, .uk-nav-default > li > a:focus, .uk-nav-default .uk-nav-sub a:hover, .uk-parent a:focus {
  color: #84deb9;
}
.left-repeat {
  padding-left: 0px;
}
#kandang-card-activity {
  height: 522px;
}
.uk-nav-sub > li > a:focus, .uk-nav-sub > li > a:hover {
  color: #84deb9;
}
@media (min-width: 1200px){
  .uk-card-body .uk-nav-default .uk-nav-sub {
      padding-left: 0px;
  }
}
.uk-card-body .uk-nav-default .uk-nav-sub {
    padding-left: 0px;
}
ul.uk-nav-sub {
    padding: 0px;
    height: 360px;
    overflow: auto;
}
.active-child {
  color:#025231 !important;
}
.active-li {
  background-color: #FFFF;
  border-radius: 16px 0px 0px 16px;
}
</style>
